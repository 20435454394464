
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'TextField',
})
export default class TextField extends Vue {
  @Prop({ type: String }) label!: string;
  @Prop({ type: Boolean }) value!: boolean;
  @Prop({ type: Boolean }) required!: boolean;
  @Prop({ type: Boolean }) disabled!: boolean;
  @Prop({ type: Function }) click!: Function;
  @Prop({ type: Boolean, default:true }) ripple!: boolean;

  @Emit('input')
  public update(value: boolean) {
    return value;
  }
}
