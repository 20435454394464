
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { chunk } from "lodash";

@Component({})
export default class Pagination extends Vue {
  // TODO: remove any and use general type
  @Prop() items!: any[];
  @Prop() isMobileViewPort!: boolean;
  itemPerPageList = [10, 20, 50];
  itemPerPage = this.itemPerPageList[0];
  length = 0;
  chunks: any;
  page = 1;
  hideArrow = false;

  scrollHandler() {
    const scrollElement = document.getElementById('locations-section');
    this.hideArrow = scrollElement!.offsetHeight + scrollElement!.scrollTop === scrollElement!.scrollHeight;
  }

  created() {
    this.resetListItems();
    setTimeout(() => {
      const scrollElement = document.getElementById('locations-section');
      scrollElement?.addEventListener(
        'scroll', this.scrollHandler)
    }, 0);
  }

  beforeDestroy() {
    const scrollElement = document.getElementById('locations-section');
    scrollElement?.removeEventListener('scroll', this.scrollHandler)
  }

  updateItemPerPage(itemPerPage: number) {
    this.itemPerPage = itemPerPage;
    this.resetListItems();
  }

  updateShownData() {
    const shownChunk = this.chunks[this.page - 1];
    this.$emit("update:shownData", shownChunk);
  }

  scrollToEnd() {
    const scrollTarget = document.getElementById('pagination-container');
    scrollTarget?.scrollIntoView({
      behavior: 'smooth'
    })
  }

  @Watch("items")
  resetListItems() {
    this.chunks = chunk(this.items, this.itemPerPage);
    this.length = this.chunks.length;
    this.page = 1;
    this.updateShownData();
  }

  @Watch("page")
  updatePage() {
    this.updateShownData();
  }
}
