
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import Card from '@/ui-components/Card/Card.vue';
import Media from '@/ui-components/Media/Media.vue';
import { LocationData } from '@/types/locations';
import IButton from '@/ui-components/IButton/IButton.vue';
import IDialog from '@/ui-components/IDialog/IDialog.vue';
import SkeletonLoader from '@/ui-components/SkeletonLoader/SkeletonLoader.vue';
import LocationCardInfo from '@/ui-components/LocationCardInfo/LocationCardInfo.vue';
import LocationCardStats from '@/ui-components/LocationCardStats/LocationCardStats.vue';
import MediaCarousel from '@/ui-components/MediaCarousel/MediaCarousel.vue';
import Loading from './components/Loading.vue';

@Component({
  components: {
    Card,
    Media,
    IButton,
    IDialog,
    SkeletonLoader,
    Loading,
    LocationCardInfo,
    LocationCardStats,
    MediaCarousel,
  },
  name: 'LocationCard',
})
export default class LocationCard extends Vue {
  @Prop({ type: Object, default: () => ({}) }) location!: LocationData;
  @Prop({ type: Boolean, default: false }) loading!: boolean;
  @Prop({ type: Boolean, default: false }) selected!: boolean;
  @Prop() activeLocationId!: string;

  @Prop({ type: Boolean, default: false }) readonly!: boolean;
  @Prop({ type: Boolean, default: false }) isMapView!: boolean;
  @Prop({ type: Boolean, default: false }) openLocationDetails!: boolean;
  @Prop({ type: Boolean, default: false }) showRegulatorIcon!: boolean;

  get mediaList() {
    return this.location.mediaList;
  }

  get media() {
    return this.location.media;
  }

  get isLocationMediaIsEmpty() {
    return !this.location.MEDIA_FILE
  }

  onActive() {
    this.$emit('update:activeLocationId', this.location.ID);
  }

  @Emit()
  select() {
    return this.location;
  }

  @Emit()
  deselect() {
    return this.location;
  }

  toggleLocationSelection(){
    if (this.selected) {
      return this.deselect();
    }
    this.select();
  }

  handleLocationSelection() {
    if (this.selected) {
      return this.deselect();
    }

    if (this.openLocationDetails) {
      this.toggleLocationDetails();
    }

    this.select();
  }

  @Emit('update:openLocationDetails')
  toggleLocationDetails() {
    return this.location.ID;
  }
}
