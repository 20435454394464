
import { Component, Prop, Vue } from "vue-property-decorator";
import { LocationData } from "@/types/locations";
import { defaultCampaignFrequency } from '@/statics/campaign-wizard';

@Component({
  name: "LocationCardStats",
})
export default class LocationCardStats extends Vue {
  @Prop({ type: Object, default: () => ({}) }) location!: LocationData;
  @Prop({ type: Boolean, default: false }) readonly !: boolean;

  public defaultFrequency = defaultCampaignFrequency;

  get resolution(): string {
    const { SCREENS_RESOLUTION: { WIDTH = '', HEIGHT = '', UNIT = '' } = {} } = this.location;

    return WIDTH && HEIGHT && UNIT ? `${WIDTH} ${UNIT} x ${HEIGHT} ${UNIT}` : '';
  }

  public get dimensions() {
    const { SCREENS_SIZES = '' } = this.location;
    return SCREENS_SIZES.replaceAll('inch', this.$t('inch') as string);
  }

  get screenLabel() {
    return this.location?.SCREENS_COUNT > 1 ? this.$t("screens") : this.$t("screen");
  }
}
