
import { Component, Vue, Prop } from "vue-property-decorator";
import { LocationData } from '@/types/locations';
import LocationCityAndAddress from '@/shared-components/LocationCityAndAddress.vue';

@Component({components:{
    LocationCityAndAddress
  }})
export default class SelectedLocationsSummary extends Vue {
  @Prop({ required: true, type: Array, default: () => [] }) selectedLocations!: LocationData[];
  selectLocation(location: LocationData){
    this.$emit('update:openLocationDetails',location.ID)
    this.$emit('update:activeLocationId',location.ID)
  }
}
