
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
  name: "ComingSoon",
})
export default class ComingSoon extends Vue {
  @Prop({ type: Boolean, default: false }) loading!: boolean;
}
