
import { Component, Vue } from 'vue-property-decorator';
import IButton from '@/ui-components/IButton/IButton.vue';
import CampaignSummaryDialog from '@/shared-components/CampaignSummaryDialog.vue';
import namespaces from '@/store/namespaces';
import { LocationData } from '@/types/locations';
import { MediaData } from '@/types/media';
import { PaymentData } from '@/types/payment';
import { TimeSlotSelection } from '@/types/time-slots';
import { Getter } from 'vuex-class';
import { CampaignSummary as ICampaignSummary } from '@/types/campaigns';

@Component({
  components: {
    IButton,
    CampaignSummaryDialog,
  },
})
export default class CampaignSummary extends Vue {  
  @Getter('selectedLocations', { namespace: namespaces.CampaignWizardModule })
  public selectedLocations!: LocationData[];

  @Getter('media', { namespace: namespaces.CampaignWizardModule })
  public media!: MediaData;

  @Getter('name', { namespace: namespaces.CampaignWizardModule })
  public name!: string;

  @Getter('time', { namespace: namespaces.CampaignWizardModule })
  public timeSlotSelection!: TimeSlotSelection;

  @Getter('payment', { namespace: namespaces.CampaignWizardModule })
  public campaignWizardPaymentData!: PaymentData;
  
  public openCampaignSummaryDialog() {
    const dialog = this.$refs.campaignSummaryDialogRef as CampaignSummaryDialog;
    dialog.open();
  }

  public get campaignSummary(): ICampaignSummary {
    const {
      name,
      selectedLocations: locations,
      timeSlotSelection: time,
      campaignWizardPaymentData: payment,
      media,
    } = this;

    return {
      name,
      locations,
      time,
      payment,
      media,
    };
  }

  public saveCampaignAsDraft() {}
}
