
import { Step } from '@/types/campaign-wizard';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'StepperHeader',
})
export default class StepperHeader extends Vue {
  @Prop({ type: Array, default: () => [] }) steps!: Step[];
  @Prop({ type: Number, default: 1 }) currentStep!: number;

  public lineStyle = {};

  public get activeStep() {
    return this.steps[this.currentStep] || {};
  }

  public isStepDone(index: number): boolean {
    return this.currentStep > index + 1;
  }

  public isStepActive(index: number): boolean {
    return this.currentStep === index + 1;
  }

  public handleStepClick(step: number) {
    if (step < this.currentStep) {
      this.$emit('goToStep', step)
    }
  }

  public lineStyleSetter() {
    const stepperHeader = (this.$refs.stepperHeader || {}) as HTMLElement;
    const widthFromElement = (stepperHeader.clientWidth || 0) / this.steps.length;
    const width = `${widthFromElement + 35 }px`
    this.lineStyle = {
      width,
    };
  }

  public mounted() {
    this.lineStyleSetter();
  }
}
