
import { Component, Prop, Vue } from 'vue-property-decorator';
import PriceSummary from '@/pages/shared/CampaignWizard/components/PriceSummary.vue';
import CampaignSummary from '@/pages/shared/CampaignWizard/components/Payment/components/CampaignSummary.vue';
import PromoCodeInput from '@/shared-components/PromoCodeInput.vue';
import { Action, Getter, Mutation } from 'vuex-class';
import namespaces from '@/store/namespaces';
import { Discount, PromoCodeData } from '@/types/promotions';
import { LocationData, LocationPrice } from '@/types/locations';
import { TimeSlotSelection } from '@/types/time-slots';
import { PaymentData } from '@/types/payment';
import { CheckoutCampaign, SetPromoCode } from '@/store/modules/campaign-wizard/types';
import { ShowSnackbar } from '@/types/snackbar';
import { defaultCampaignFrequency } from '@/statics/campaign-wizard';
import { priceByFrequency } from '@/utils/numbers';
@Component({
  components: {
    PriceSummary,
    CampaignSummary,
    PromoCodeInput,
  }
})
export default class RightPaymentSection extends Vue{
  @Prop({ }) closeSheet!:Function;
  @Getter('discounts', { namespace: namespaces.CampaignWizardModule })
  public discounts!: Discount[];
  @Getter("selectedLocations", { namespace: namespaces.CampaignWizardModule })
  public selectedLocations!: LocationData[];
  @Getter('time', { namespace: namespaces.CampaignWizardModule })
  public timeSlotSelection!: TimeSlotSelection;
  @Getter('payment', { namespace: namespaces.CampaignWizardModule })
  public campaignWizardPaymentData!: PaymentData;
  @Getter('promoCode', { namespace: namespaces.CampaignWizardModule })
  public campaignWizardPromoCode!: PromoCodeData;


  @Mutation('setPromoCode', { namespace: namespaces.CampaignWizardModule })
  public setPromoCode!: SetPromoCode;


  @Action('checkoutCampaign', { namespace: namespaces.CampaignWizardModule })
  public checkoutCampaign!: CheckoutCampaign;
  @Action('showSnackbar', { namespace: namespaces.UiModule })
  public showSnackbar!: ShowSnackbar;


  public loading = true;
  public promoCodeLoading = false;
  locationPrices = {} as Record<LocationData['ID'], LocationPrice>;

  private paymentErrorSnackbar() {
    const color = 'danger';
    const text = this.$t('something_went_wrong') as string;
    this.showSnackbar({ text, color });
  }

  public async loadCheckoutData() {
    try {
      this.loading = true;
      this.promoCodeLoading = true;
      await this.checkoutCampaign();
    } catch (err) {
      this.paymentErrorSnackbar()
    } finally {
      this.loading = false;
      this.promoCodeLoading = false;
    }
  }
  public async promoCodeChanged() {
    try {
      this.promoCodeLoading = true;
      await this.loadCheckoutData();
    } catch (error) {
    } finally {
      this.promoCodeLoading = false;
    }
  }

  public get promoCode(): PromoCodeData {
    return this.campaignWizardPromoCode;
  }
  public set promoCode(promoCode: PromoCodeData) {
    this.setPromoCode(promoCode);
  }
  updatePrice() {
    const {
      frequency = defaultCampaignFrequency
    } = this.timeSlotSelection;
    this.locationPrices = this.selectedLocations.reduce((selectedLocations, selectedLocation: LocationData) => {
      return {
        ...selectedLocations,
        [selectedLocation.clusterId]: {
          BASE_PRICE: priceByFrequency(selectedLocation.BASE_PRICE, frequency)
        }
      }
    }, {})
  }

  created() {
    this.updatePrice()
  }
}
