
import { Component, Vue, Prop } from 'vue-property-decorator';
import { LocationData } from '@/types/locations';
import Truncated from '@/shared-components/Truncated.vue'
@Component({
  name: "LocationCityAndAddress",
  components :{
    Truncated
  }})
export default class LocationCityAndAddress extends Vue{
  @Prop({required:true}) location!:LocationData
  @Prop({required:true}) maxLength!:number
  @Prop() positionFixIcon!:boolean
}
