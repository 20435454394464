
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'Stepper',
})
export default class Stepper extends Vue {
  @Prop({ type: Number, default: 0 }) value!: number;

  @Emit('input')
  update(value: number) {
    return value;
  }
}
