
import { Component, Prop, Vue } from 'vue-property-decorator';
import GuideLinesDialog from '../pages/shared/CampaignWizard/components/AdContent/components/GuideLinesDialog.vue';
import { ScreenSpecs as IScreenSpecs } from '@/types/screens';

@Component({
  components: {
    GuideLinesDialog,
  },
})
export default class ScreenSpecs extends Vue {
  @Prop({ type: Object, default: () => ({}) }) screenSpecs!: IScreenSpecs;

  public openGuideLinesModal() {
    const dialog = this.$refs.guideLinesDialogRef as GuideLinesDialog;
    dialog.open();
  }

  public downloadGuideLines() {}
}
