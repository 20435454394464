
import { Component, Vue } from "vue-property-decorator";
import Media from "@/ui-components/Media/Media.vue";
import IButton from "@/ui-components/IButton/IButton.vue";
import IDialog from "@/ui-components/IDialog/IDialog.vue";
import { rules } from "@/validation-rules";
import TextField from "@/ui-components/TextField/TextField.vue";

@Component({
  components: { Media, IButton, IDialog, TextField },
})
export default class GuideLinesDialog extends Vue {
  public isOpen = false;
  public valid = false;
  public designerEmail = "";

  public get designerEmailRules() {
    return [
      rules.required(this.$i18n.t("please_enter_designer_email") as string),
      rules.emailFormat(this.$i18n.t("invalid_email") as string),
    ];
  }

  public open() {
    this.isOpen = true;
  }

  public send() {}

  public close() {
    this.isOpen = false;
  }
}
