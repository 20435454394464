
import { Component, Prop, Vue } from "vue-property-decorator";
import { LocationData, StoreOwnerLocationData } from "@/types/locations";
import  LocationCityAndAddress from '@/shared-components/LocationCityAndAddress.vue';
import { adSpaceTypesIcons } from '@/statics/ad-spaces';

@Component({
  name: "LocationCardInfo",
  components:{
    LocationCityAndAddress,
  }
})
export default class LocationCardInfo extends Vue {
  @Prop({ type: Object, default: () => ({}) }) location!: LocationData|StoreOwnerLocationData;
  @Prop({ type: Boolean }) readonly!: Boolean;
  @Prop({ type: Boolean, default: false }) showRegulatorIcon!: boolean;

  get locationTypeImage():string{
    return adSpaceTypesIcons[this.location.TYPE.VAL]?.icon||'';
  }
}
