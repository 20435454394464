
import { Component, Vue } from "vue-property-decorator";

import SkeletonLoader from "@/ui-components/SkeletonLoader/SkeletonLoader.vue";

@Component({
  components: {
    SkeletonLoader,
  },
  name: "Loading",
})
export default class Loading extends Vue {}
