
import { Component, Prop, Vue } from 'vue-property-decorator';
import IButton from '@/ui-components/IButton/IButton.vue';
import { Action } from 'vuex-class';
import namespaces from '@/store/namespaces';
import { DeselectAllLocations, SelectLocations } from '@/store/modules/campaign-wizard/types';
import { LocationData } from '@/types/locations';

@Component({
  components: {
    IButton,
  }
})
export default class SelectAllLocationToggle extends Vue {
  @Prop({ required: true, default: () => [] }) filteredLocations!: LocationData[];

  @Action("selectLocations", { namespace: namespaces.CampaignWizardModule })
  public selectLocations!: SelectLocations;

  @Action("deselectAllLocations", { namespace: namespaces.CampaignWizardModule })
  public deselectAllLocations!: DeselectAllLocations;
  public selected: boolean = false;

  get filteredLocationsHaveAtLeastOneLocationSelectable(): boolean {
    return this.filteredLocations.some(location => location.selectable);
  }
  toggleSelect() {
    if (this.selected) {
      this.deselectAll()
    } else {
      this.selectAll()
    }
    this.selected = !this.selected;
  }

  selectAll() {
    const selectableLocations = this.filteredLocations.filter(location => location.selectable);
    this.selectLocations(selectableLocations);
  }

  deselectAll() {
    this.deselectAllLocations()
  }
}
