export const observe = (sel: string, opt: any, cb: any) => {
  const Obs = new MutationObserver((mutations: any[]) =>
    [...mutations].forEach(cb)
  );
  document.querySelectorAll(sel).forEach((el) => Obs.observe(el, opt));
};

type Tooltip = {
  id: string;
  innerText: string;
  initialClassName: string;
  className: string;
  hideClassName: string;
  animationDuration?: number;
  parentElement: Element;
}
export const customTooltip = ({
  id,
  innerText,
  parentElement,
  initialClassName,
  className,
  hideClassName,
  animationDuration
}: Tooltip) => {
  const tooltipContainerEl = document.createElement('div');
  tooltipContainerEl.id = id;
  const tooltipEl = document.createElement('span');
  tooltipEl.innerText = innerText;
  tooltipEl.className = initialClassName;
  tooltipContainerEl?.appendChild(tooltipEl)
  let timeout: NodeJS.Timeout;
  parentElement.addEventListener('mouseover', () => {
    if (timeout)
      clearTimeout(timeout)
    tooltipEl.className = initialClassName;
    parentElement?.appendChild(tooltipContainerEl)
    setTimeout(() => {
      tooltipEl.className = className;
    }, 0)
  })

  parentElement.addEventListener('mouseout', () => {
    tooltipEl.className = hideClassName;
    timeout = setTimeout(() => {
      const tooltip = document.getElementById(id)
      tooltip && parentElement?.removeChild(tooltip)
    }, animationDuration)
  })
}
