
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'StepperStep',
})
export default class StepperStep extends Vue {
  @Prop({ type: Boolean, default: false }) complete!: boolean;
  @Prop({ type: Number }) step!: number;

  @Emit('click')
  clicked(event: MouseEvent) {
    return event;
  }
}
