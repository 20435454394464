var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.cities.length > 1)?_c('v-flex',{staticClass:"xs12"},[_c('v-select',{attrs:{"data-select-city":"","items":_vm.cities,"label":"City","attach":"","item-value":"GEONAME_ID","multiple":"","menu-props":{
        bottom: true,
        maxHeight: 240,
        minWidth: 220,
        allowOverflow: true,
        nudgeBottom: 60,
        contentClass: 'overflow-y-auto no-list-padding'
      },"clear-icon":"mdi-close-circle","deletable-chips":"","clearable":"","outlined":""},on:{"change":_vm.onChangedFilters},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{staticClass:"appended-icon",attrs:{"data-city-select-menu-arrow":""}},[_vm._v("mdi-menu-down")])]},proxy:true},{key:"prepend-item",fn:function(){return [_c('select-menu-search',{attrs:{"placeholder":_vm.$t('search_a_city').toString(),"focus":_vm.focus,"search-text":_vm.searchText,"update-search-results":_vm.citiesSearch,"search-result":_vm.searchResult},on:{"update:focus":function($event){_vm.focus=$event},"update:searchText":function($event){_vm.searchText=$event},"update:search-text":function($event){_vm.searchText=$event}},scopedSlots:_vm._u([{key:"search-result",fn:function(){return [_vm._l((_vm.searchResult),function(city){return (_vm.searchText.length > 0)?_c('div',{staticClass:"search-result-item"},[(city.region)?_c('span',[_c('select-check-box',{attrs:{"value":_vm.isCitySelected(city.GEONAME_ID),"click":() => _vm.toggleCitySelect(city.GEONAME_ID)},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"d-flex flex-column"},[_c('label',{staticClass:"ms-2 search-result-region-text pointer"},[_vm._v(_vm._s(_vm.$t(`regions.${ city.region.DISPLAY_NAME.en }`).toString()))]),_c('label',{staticClass:"ms-2 light-grey pt-1 search-result-region-text pointer"},[_vm._v(_vm._s(city.region.DISPLAY_NAME.ar))])]),_c('v-icon',{staticClass:"search-right-icon"},[_vm._v("mdi-chevron-right")]),_c('div',{staticClass:"flex-column d-flex"},[_c('label',{staticClass:"search-result-city-text pointer"},[_vm._v(_vm._s(_vm.$t(`cities.${ city.DISPLAY_NAME.en }`).toString()))]),_c('label',{staticClass:"search-result-city-text light-grey ps-3 pointer"},[_vm._v(_vm._s(city.DISPLAY_NAME.ar))])])]},proxy:true}],null,true)})],1):_c('div',{class:{
               'selected-city':_vm.isCitySelected(city.GEONAME_ID)
            }},[_c('select-check-box',{attrs:{"value":_vm.isCitySelected(city.GEONAME_ID),"label":{ en: _vm.$t(`cities.${city.DISPLAY_NAME.en}`).toString(), ar: null},"click":() => _vm.toggleCitySelect(city.GEONAME_ID)}})],1)]):_vm._e()}),(_vm.searchText.length > 0 && _vm.searchResult.length === 0)?_c('div',[_c('span',{staticClass:"no-search-results"},[_vm._v(_vm._s(_vm.$t('no_search_results')))])]):_vm._e()]},proxy:true}],null,false,2218621338)})]},proxy:true},{key:"item",fn:function({ item: city }){return [(_vm.focus)?[_c('span')]:[(city.regionCities.length === 0)?_c('span',{staticClass:"list-item-padding",class:{ 'd-none': city.regionCities.length===0 }},[_c('select-check-box',{attrs:{"value":_vm.isCitySelected(city.GEONAME_ID),"label":{ en: _vm.$t(`regions.${city.DISPLAY_NAME.en}`).toString(), ar: city.DISPLAY_NAME.ar},"click":() => _vm.toggleCitySelect(city.GEONAME_ID)}})],1):_c('v-menu',{attrs:{"close-on-content-click":false,"content-class":"responsive-position","bottom":"","offset-x":"","nudge-right":16},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',{class:[{
                'selected-city':
                  _vm.regionHasSomeCitiesSelected(city.regionCities)
              },'d-flex justify-space-between w-100 list-item-padding'],on:{"click":function($event){$event.stopPropagation();_vm.isRegionMenuOpenMap[city.GEONAME_ID] = !_vm.isRegionMenuOpenMap[city.GEONAME_ID]}}},[_c('span',[_c('select-check-box',{staticClass:"region-ar-text",attrs:{"some-selected":_vm.regionHasSomeCitiesSelected(city.regionCities) && !_vm.regionHasAllCitiesSelected(city.regionCities),"value":_vm.regionHasAllCitiesSelected(city.regionCities),"label":{ en: _vm.$t(`regions.${city.DISPLAY_NAME.en}`).toString(), ar: city.DISPLAY_NAME.ar },"click":() => _vm.toggleAllRegionCities([city.GEONAME_ID,city.regionCities])}}),_c('span',{staticClass:"ar-text"},[_vm._v(_vm._s())])],1),_c('v-icon',{staticClass:"right-icon",attrs:{"data-region-right-arrow":""}},[_vm._v("mdi-chevron-right")])],1)]}}],null,true),model:{value:(_vm.isRegionMenuOpenMap[city.GEONAME_ID]),callback:function ($$v) {_vm.$set(_vm.isRegionMenuOpenMap, city.GEONAME_ID, $$v)},expression:"isRegionMenuOpenMap[city.GEONAME_ID]"}},[_c('v-list',{staticClass:"region-cities-list"},_vm._l((city.regionCities),function(regionCity,index){return _c('v-list-item',{key:_vm.$t(`cities.${regionCity.DISPLAY_NAME.en}`).toString() +
                        index,staticClass:"ps-6"},[_c('v-list-item-title',[_c('select-check-box',{attrs:{"value":_vm.isCitySelected(regionCity.GEONAME_ID),"label":{ en: _vm.$t(`cities.${regionCity.DISPLAY_NAME.en}`).toString(), ar: regionCity.DISPLAY_NAME.ar},"click":() => _vm.toggleCitySelect(regionCity.GEONAME_ID)}})],1)],1)}),1)],1)]]}},{key:"selection",fn:function({ item: city, index }){return [(index === 0)?_c('v-chip',[_c('span',{attrs:{"data-selected-item":""}},[_vm._v(_vm._s(_vm.$t(`cities.${ city.DISPLAY_NAME.en }`).toString()))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" +"+_vm._s(_vm.selectedCities.length - 1)+" ")]):_vm._e()]}}],null,false,3854243416),model:{value:(_vm.selectedCities),callback:function ($$v) {_vm.selectedCities=$$v},expression:"selectedCities"}})],1):_vm._e(),(_vm.locationType.length > 1)?_c('v-flex',{staticClass:"select-menu-padding-target"},[_c('v-select',{attrs:{"data-select-type":"","items":_vm.locationType,"label":"Type","attach":"","multiple":"","menu-props":{
        bottom: true,
        maxHeight: 150,
        allowOverflow: true,
        nudgeBottom: 60,
        maxWidth:'max-content',
        contentClass: 'overflow-y-auto'
      },"item-text":"DISPLAY_NAME","item-value":"VAL","clear-icon":"mdi-close-circle","deletable-chips":"","clearable":"","outlined":""},on:{"change":_vm.onChangedFilters},scopedSlots:_vm._u([{key:"item",fn:function({ item: itemLocationType }){return [_c('div',{staticClass:"location-type-item",attrs:{"data-type-filter-item":""}},[_c('select-check-box',{attrs:{"value":_vm.selectedLocationTypes.includes(itemLocationType.VAL)}}),_c('img',{staticClass:"pe-1",attrs:{"src":_vm.locationTypeImage(itemLocationType),"width":"20","height":"20","alt":""}}),_vm._v(" "+_vm._s(itemLocationType.DISPLAY_NAME)+" ")],1)]}},{key:"selection",fn:function({ item: itemLocationType, index }){return [(index === 0)?_c('v-chip',[_c('span',{staticClass:"d-flex align-center"},[_c('img',{staticClass:"pe-1",attrs:{"src":_vm.locationTypeImage(itemLocationType),"width":"16","height":"16","alt":""}})]),_c('span',[_vm._v(_vm._s(itemLocationType.DISPLAY_NAME))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" +"+_vm._s(_vm.selectedLocationTypes.length - 1)+" ")]):_vm._e()]}}],null,false,2077521772),model:{value:(_vm.selectedLocationTypes),callback:function ($$v) {_vm.selectedLocationTypes=$$v},expression:"selectedLocationTypes"}})],1):_vm._e(),_c('v-flex',{staticClass:"d-md-block d-none xs12 select-menu-padding-target"},[_c('select-all-location-toggle',{attrs:{"filtered-locations":_vm.filteredLocations}})],1),_c('v-flex',{staticClass:"d-md-none d-block xs12 select-menu-padding-target"},[_c('i-button',{staticClass:"w-100 mt-115",attrs:{"color":"primary","outlined":""},on:{"click":_vm.clearAllFilters},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$t("clear_all")))])]},proxy:true}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }