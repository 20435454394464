
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'StepperContent',
})
export default class StepperContent extends Vue {
  @Prop({ type: Number }) step!: number;
  @Prop({ type: Number }) currentStep!: number;
}
