
import { Component, Vue, Prop } from "vue-property-decorator";
import { LocationData } from '@/types/locations';
import IButton from '@/ui-components/IButton/IButton.vue';
import LocationSelects from '@/pages/shared/CampaignWizard/components/Locations/components/LocationSelects.vue';
import SelectAllLocationToggle
  from '@/pages/shared/CampaignWizard/components/Locations/components/SelectAllLocationToggle.vue';


@Component({
  components: {
    IButton,
    LocationSelects,
    SelectAllLocationToggle,
  }
})
export default class LocationFilters extends Vue {
  @Prop({ required: true, default: () => [] }) locations!: LocationData[];
  @Prop({ required: true, default: () => [] }) filteredLocations!: LocationData[];
  public sheet: boolean = false;

  filteredLocationsMediator: LocationData[] = this.filteredLocations;

  /* TODO: refactor the way this component edits the filteredLocations
  and emit it back to the parent component */
  get _filteredLocationsMediator() {
    return this.filteredLocationsMediator;
  }
  set _filteredLocationsMediator(val: LocationData[]) {
    this.filteredLocationsMediator = val;
    this.$emit('update:filteredLocations', val)
  }
  clearFilters(){
    const locationSelects = this.$refs.locationSelects as LocationSelects;
    const locationSelectsResponsive = this.$refs.locationSelectsResponsive as LocationSelects;
    locationSelects.clearAllFilters()
    locationSelectsResponsive.clearAllFilters()
  }
}
