
import { Component, Prop, Vue } from 'vue-property-decorator';
import { MultiLanguage } from '@/types/misc';

@Component({
  name: "SelectCheckBox",
  components: {},
})
export default class SelectCheckBox extends Vue {
  @Prop({ type: Boolean }) value!: boolean;
  @Prop({ type: Boolean }) someSelected!: boolean;
  @Prop({ default: {} }) label!: MultiLanguage;
  @Prop({ type: Function }) click!: Function;

  handleClick(event: Event) {
    if (this.click) {
      event.stopPropagation();
      this.click()
    }
  }
}
