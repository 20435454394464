
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class WizardHeader extends Vue {
  @Prop({ type: String }) header!: string;
  @Prop({ type: String }) subHeader!: string;
}
