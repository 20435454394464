
import { Component, Prop, Vue } from 'vue-property-decorator';
import Media from '@/ui-components/Media/Media.vue';
import IButton from '@/ui-components/IButton/IButton.vue';
import IDialog from '@/ui-components/IDialog/IDialog.vue';
import TextField from '@/ui-components/TextField/TextField.vue';

@Component({
  components: { Media, IButton, IDialog, TextField },
})
export default class CampaignNameDialog extends Vue {
  @Prop() public campaignName!: string;
  @Prop() public campaignNameHint!: string;
  @Prop() public campaignNameRules!: ((value: string) => (string | boolean))[];

  public get usedCampaignName(): string {
    return this.campaignName;
  }

  public set usedCampaignName(val: string) {
    this.$emit('update:campaignName', val);
  }

  public isOpen = false;

  public open() {
    this.isOpen = true;
  }

  public close() {
    this.isOpen = false;
  }
}
