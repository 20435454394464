
import { FREQUENCY_SLIDER_VALUES, campaignFrequencies, campaignSliderFrequencies } from '@/statics/campaign-wizard';
import { CampaignFrequency, FREQUENCY } from '@/types/campaigns';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import namespaces from '@/store/namespaces';
import { LocationData } from '@/types/locations';
import { isAllSelectedLocationsPriceControlByShasha } from '@/utils/campaigns';

@Component({
  components: {},
})
export default class TimeSelection extends Vue {
  @Prop({ type: Object }) frequency!: CampaignFrequency;

  @Getter('selectedLocations', { namespace: namespaces.CampaignWizardModule })
  public selectedLocations!: LocationData[];

  public defaultFrequencyName = this.frequency.NAME;
  public frequencies = campaignFrequencies;
  public timeFrequenciesInMinutes: number[] = [];

  public get sliderValue() {
    return FREQUENCY_SLIDER_VALUES[this.frequency.NAME];
  }

  public set sliderValue(sliderValue: number) {
    const selectedFrequency = campaignSliderFrequencies[sliderValue];
    this.$emit('update:frequency', selectedFrequency);
  }

  public get frequencyInMinutes() {
    return this.frequency.MINUTES;
  }

  public get tickLabels() {
    return Object.values(this.frequencies).map(({ LABEL }) => this.$t(LABEL));
  }

  get isAllSelectedLocationsPriceControlByShasha() {
    return isAllSelectedLocationsPriceControlByShasha(this.selectedLocations);
  }

  public mounted() {
    if (this.isAllSelectedLocationsPriceControlByShasha) {
      this.sliderValue = FREQUENCY_SLIDER_VALUES[this.frequency.NAME];
    } else {
      this.sliderValue = FREQUENCY_SLIDER_VALUES[FREQUENCY['2X']];
    }
    this.timeFrequenciesInMinutes = Object.values(this.frequencies).map(({ MINUTES }) => MINUTES);
  }

  @Watch('frequency')
  highlightSelectedFrequency() {
    const selectedFrequency = document.querySelector('.selected-frequency-minutes');
    if (selectedFrequency) {
      selectedFrequency.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }
}
