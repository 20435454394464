
import { Component, Prop, Vue } from 'vue-property-decorator';
import { TimeSlotSelection } from '@/types/time-slots';
import { LocationData, LocationPrice } from '@/types/locations';
import { Discount, PromotionType } from '@/types/promotions';
import Divider from '@/ui-components/Divider/Divider.vue';
import LocationCityAndAddress from '@/shared-components/LocationCityAndAddress.vue';
import {
  addVatTo,
  calcVatOn,
  toNumberOr,
  VAT_PERCENTAGE,
} from '@/utils/numbers';
import { chain } from 'lodash';
import { CampaignFrequency } from '@/types/campaigns';
import { isAllSelectedLocationsPriceControlByShasha } from '@/utils/campaigns';

@Component({
  components: {
    Divider,
    LocationCityAndAddress,
  },
})
export default class PriceSummary extends Vue {
  @Prop({ required: true, type: Array, default: () => [] })
  selectedLocations!: LocationData[];
  @Prop({ required: true, type: Object }) timeSlotSelection!: TimeSlotSelection;
  @Prop({ type: Array, default: () => [] }) discounts!: Discount[];
  @Prop({ type: Object, required: true }) locationPrices!: Record<LocationData['ID'], LocationPrice>;
  @Prop({ }) closeSheet!:Function;

  get isAllSelectedLocationsPriceControlByShasha() {
    return isAllSelectedLocationsPriceControlByShasha(this.selectedLocations)
  }
  get priceSummaryTitle(){
    return this.isAllSelectedLocationsPriceControlByShasha? this.$t('price_summary'): this.$t('budgetary_summary');
  }
  public get frequencyName() {
    const { NAME = '' } = this.timeSlotSelection.frequency || {} as CampaignFrequency;
    return this.$t(NAME);
  }

  public get subTotalCampaignPrice() {
    const {
      duration = 0,
    } = this.timeSlotSelection;
    const selectedDuration = toNumberOr(duration, 0);

    const subtotal = this.selectedLocations.reduce(
      (total, location) => {
        const locationPrice = this.locationPrices?.[location.clusterId]?.BASE_PRICE;
        return total + selectedDuration * locationPrice
      },
      0
    );

    return subtotal;
  }

  public get totalCampaignDiscounts() {
    return chain(this.discounts)
      .map(({ discountValue }) => discountValue)
      .sum()
      .value();
  }

  public get subTotalCampaignPriceAfterDiscount() {
    return this.subTotalCampaignPrice - this.totalCampaignDiscounts;
  }

  public get totalCampaignPrice() {
    const totalAfterVat = addVatTo(this.subTotalCampaignPriceAfterDiscount);
    return totalAfterVat;
  }

  public discountInfo({ discountType = {} as PromotionType }: Discount) {
    const { RATE, VALUE } = discountType;
    const promotionType = RATE === 'DISCOUNT' ? `%` : ` ${this.$t('sar')}`;
    return `${VALUE}${promotionType} ${this.$t('off')}`;
  }

  public discountValue(discount: Discount) {
    return discount.discountValue;
  }

  public get vatValue() {
    return calcVatOn(this.subTotalCampaignPriceAfterDiscount);
  }

  public get vatInfo() {
    return `+${VAT_PERCENTAGE}% ${this.$t('vat')}`;
  }

  public calculateLocationPrice(location: LocationData) {
    const {
      duration,
    } = this.timeSlotSelection;
    const selectedDuration = toNumberOr(duration, 0);
    const locationPrice = Number(this.locationPrices?.[location.clusterId]?.BASE_PRICE);
    return locationPrice * selectedDuration;
  }
}
