
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { City, CitySelectMenuItem, LocationData, Region, RegionCity } from "@/types/locations";
import IButton from "@/ui-components/IButton/IButton.vue";
import SelectAllLocationToggle from "./SelectAllLocationToggle.vue";
import Checkbox from '@/ui-components/Checkbox/Checkbox.vue';
import { adSpaceTypesIcons } from '@/statics/ad-spaces';
import { AdSpaceType } from '@/types/adspaces';
import SelectCheckBox
  from '@/pages/shared/CampaignWizard/components/Locations/components/components/SelectCheckBox.vue';
import { cloneDeep, groupBy, orderBy } from 'lodash';
import { Tuple } from '@/types/misc';
import TextField from '@/ui-components/TextField/TextField.vue';
import Divider from '@/ui-components/Divider/Divider.vue';
import SelectMenuSearch from '@/ui-components/SelectMenuSearch/SelectMenuSearch.vue';

@Component({
  components: {
    SelectMenuSearch,
    Divider,
    IButton,
    SelectAllLocationToggle,
    Checkbox,
    SelectCheckBox,
    TextField,

  }
})
export default class LocationSelects extends Vue {
  @Prop({ required: true, default: () => [] }) locations!: LocationData[];
  @Prop({ required: true, default: () => [] })
  filteredLocations!: LocationData[];


  public selectedCities: Array<CitySelectMenuItem["GEONAME_ID"]> = [];
  public selectedLocationTypes: string[] = [];
  public sheet: boolean = false;
  public selected: boolean = false;
  isRegionMenuOpenMap: Record<string, boolean> = {};
  searchText = '';
  focus = false;
  cachedCities: CitySelectMenuItem[] = [];
  searchResult: CitySelectMenuItem[] = [];


  citiesSearch(searchText: string) {
    if (this.cachedCities.length === 0) {
      this.cachedCities = cloneDeep(this.cities);
    }
    const searchResult = this.cachedCities.filter((city) => {
      const searchRegex = new RegExp(searchText, 'i')
      return city.regionCities?.length === 0
        && (
          searchRegex.test(this.$t(city.DISPLAY_NAME.en).toString()) ||
          (city.DISPLAY_NAME.ar && searchRegex.test(city.DISPLAY_NAME.ar))
        )
    })
    this.searchResult = orderBy(searchResult, (city) => city.POPULATION || 0, 'desc')
  }

  getCityWithGeonameId(regionCities: CitySelectMenuItem[], geonameId: string) {
    return regionCities.find(regionCity => regionCity.GEONAME_ID === geonameId)
  }

  isCitySelected(geonameId: string): boolean {
    return this.selectedCities.includes(geonameId)
  }

  regionHasSomeCitiesSelected(regionCities: CitySelectMenuItem[]) {
    return regionCities.some((regionCity) => this.isCitySelected(regionCity.GEONAME_ID))
  }

  regionHasAllCitiesSelected(regionCities: CitySelectMenuItem[]) {
    return regionCities.every((regionCity) => this.isCitySelected(regionCity.GEONAME_ID))
  }

  // TODO: refactor to more concise logic
  get cities(): CitySelectMenuItem[] {
    const uniqueRegionCitiesRecord = this.locations.reduce((cities, location) => {
      return {
        ...cities,
        [location.CITY.GEONAME_ID]: {
          ...location.CITY,
          ...location.REGION && {
            region: location.REGION,
          }
        },

      }
    }, {} as Record<RegionCity["GEONAME_ID"], RegionCity>);

    let uniqueRegionsRecord = this.locations.reduce((regions, location) => {
      return {
        ...regions,
        ...location.REGION && {
          [location.REGION.GEONAME_ID]: {
            ...location.REGION,
          }
        },

      }
    }, {} as Record<Region["GEONAME_ID"], Region>);

    const groupedCitiesByRegion: Record<Region["GEONAME_ID"], RegionCity[]> =
      groupBy(Object.values(uniqueRegionCitiesRecord), (city) => city.region?.GEONAME_ID);

    const orderedRegionsByPopulation = orderBy(Object.values(uniqueRegionsRecord) as CitySelectMenuItem[],
      (region) => region.POPULATION || 0,
      'desc');

    const orderedRegionCitiesRecordByPopulation = Object.entries(groupedCitiesByRegion)
      .reduce((orderedMap, [regionGeonameId, cities]) => {
        return {
          ...orderedMap,
          [regionGeonameId]: orderBy(cities, (city) => city.POPULATION || 0, 'desc'),
        }
      }, {} as Record<Region["GEONAME_ID"], CitySelectMenuItem[]>);

    const orderedRegionsWithRegionCitiesByPopulation = orderedRegionsByPopulation.map((region) => {
      region.regionCities = orderedRegionCitiesRecordByPopulation[region.GEONAME_ID];
      return region;
    })

    orderedRegionsWithRegionCitiesByPopulation.forEach((region) => {
      this.isRegionMenuOpenMap = { ...this.isRegionMenuOpenMap, [region.GEONAME_ID]: false };
    })

    return orderedRegionsWithRegionCitiesByPopulation.concat(
      Object.values(uniqueRegionCitiesRecord as Record<City["GEONAME_ID"], CitySelectMenuItem>).map((city) => {
        city.regionCities = [];
        return city;
      }));
  }

  get brandNames(): string[] {
    const uniqueBrandsSet = this.locations.reduce(
      (brands, { BRAND_NAME }) => brands.add(BRAND_NAME),
      new Set() as Set<string>
    );
    return Array.from(uniqueBrandsSet).filter(Boolean);
  }

  locationTypeImage(itemLocationType: AdSpaceType): string {
    return adSpaceTypesIcons[itemLocationType.VAL]?.icon || '';
  }


  get locationType() {
    const uniqueLocationType = this.locations.reduce(
      (type, location) => ({
        ...type,
        [location.TYPE.VAL]: {
          VAL: location.TYPE.VAL,
          DISPLAY_NAME: this.$t(location.TYPE.VAL)
        }
      }),
      {}
    );
    return Object.values(uniqueLocationType);
  }

  created() {
    this.onChangedFilters();
  }

  @Emit('update:filteredLocations')
  public onChangedFilters(): LocationData[] {
    let filteredLocations = this.locations;
    if (this.selectedCities.length) {
      filteredLocations = filteredLocations.filter(location =>
        this.isCitySelected(location.CITY.GEONAME_ID)
      );
    }
    if (this.selectedLocationTypes.length) {
      filteredLocations = filteredLocations.filter(location =>
        this.selectedLocationTypes.includes(location.TYPE.VAL)
      );
    }
    return filteredLocations;
  }

  clearAllFilters() {
    this.selectedCities = [];
    this.selectedLocationTypes = [];
    this.onChangedFilters();
  }

  toggleCitySelect(geonameId: string) {
    if (this.isCitySelected(geonameId)) {
      this.selectedCities = this.selectedCities.filter((cityId) => cityId !== geonameId)
    } else {
      this.selectedCities.push(geonameId)
    }
    this.onChangedFilters();
  }

  toggleAllRegionCities([geonemeId, regionCities]: Tuple<string, CitySelectMenuItem[]>) {
    if (!this.regionHasAllCitiesSelected(regionCities)) {
      this.isRegionMenuOpenMap[geonemeId] = true;
      regionCities.forEach((regionCity) => {
        if (!this.isCitySelected(regionCity.GEONAME_ID)) {
          this.selectedCities = this.selectedCities.concat(regionCity.GEONAME_ID)
        }
      })
    } else {
      this.selectedCities = this.selectedCities.filter((geonameId) => !this.getCityWithGeonameId(regionCities, geonameId))

    }
    this.onChangedFilters()
  }

}
