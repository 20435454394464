
import { Step } from '@/types/campaign-wizard';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import namespaces from '@/store/namespaces';
import { LocationData } from '@/types/locations';

@Component({
  name: 'StepperHeaderResponsive',
})
export default class StepperHeaderResponsive extends Vue {
  @Prop({ type: Array, default: () => [] }) steps!: Step[];
  @Prop({ type: Number, default: 1 }) currentStep!: number;
  @Prop({ type: String, default: '' }) blueTitle!: string;
  @Prop({ type: Boolean, default: false }) isGraySelected!: boolean;
  @Prop({ type: Boolean, default: false }) isBorderBottom!: boolean;
  @Prop({ type: String, default: '' }) sheetClassName!: string;

  public sheet = false;

  @Getter("selectedLocations", { namespace: namespaces.CampaignWizardModule })
  public selectedLocations!: LocationData[];

  @Getter('isAuthenticated', { namespace: namespaces.AuthModule })
  public isAuthenticated!: boolean;


  toggleSheet() {
    if (this.blueTitle) {
      this.sheet = !this.sheet;
    }
  }


}
