
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Nullable } from '@/types/misc';
import Divider from '@/ui-components/Divider/Divider.vue';

@Component({
  name: "SelectMenuSearch",
  components: {
    Divider,
  },
})
export default class SelectMenuSearch extends Vue {
  @Prop() updateSearchResults!: Function;
  @Prop() placeholder!: string;
  @Prop() searchResult!: Array<any>;

  searchText = '';
  focus = false;

  exitSearchMode() {
    this.focus = false;
    const searchRef = this.$refs.searchRef as Nullable<HTMLInputElement>;
    searchRef?.blur()
  }

  clearSearchInput() {
    this.searchText = ''
  }

  @Watch('focus')
  focusChanged(focus: boolean) {
    this.$emit('update:focus', focus)
  }

  @Watch('searchText')
  searchTextChanged(newSearchText: string) {
    this.$emit('update:searchText', newSearchText)
  }
}
