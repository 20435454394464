
import { Discount, PromoCodeData } from '@/types/promotions';
import TextField from '@/ui-components/TextField/TextField.vue';
import IButton from '@/ui-components/IButton/IButton.vue';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'PromoCodeInput',
  components: {
    TextField,
    IButton,
  },
})
export default class PromoCodeInput extends Vue {
  @Prop({ type: Object })
  public promoCode!: PromoCodeData;

  @Prop({ type: Number })
  public totalDiscount!: number;

  @Prop({ type: Boolean })
  public loading!: boolean;

  public showInput = false;

  public get isApplied() {
    return this.promoCode.status === 'APPLIED';
  }

  public get hasError() {
    return this.promoCode.error;
  }

  public get appLocale() {
    return this.$i18n.locale;
  }

  public get errorMessage() {
    const { error = '' } = this.promoCode;
    return error ? this.$t(`errors.${error}`) : '';
  }

  @Emit('changed')
  @Emit('update:promoCode')
  public remove() {
    return {
      code: '',
    };
  }

  @Emit('changed')
  public apply() {
    return true;
  }

  public created() {
    if (this.promoCode.code) {
      this.showInput = true;
    }
  }
}
